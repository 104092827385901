import React, { useContext } from "react"
import PropTypes from "prop-types"

import makeStyles from "@material-ui/core/styles/makeStyles"
import LangContext from "../../contexts/LangContext"
import { Avatar } from "@material-ui/core"
import { Link } from "gatsby"

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    title: {
        background: theme.palette.secondary.main,
        color: theme.palette.common.white,
        padding: 8,
        fontSize: 16,
        fontWeight: 700,
        display: 'flex',
        marginBottom: 4,
        alignItems: 'center',
        gap: 8,

        '& a': {
            color: theme.palette.common.white,
        }
    },
    avatar: {
        width: 40,
        height: 40
    },
    list: {
        display: 'flex',
        flexDirection: 'column',
        gap: 4
    },

    listItem: {
        fontSize: 15,
        padding: 4,
        border: `1px solid #aaa`,
        display: 'flex',
        alignItems: "center",
        justifyContent: 'space-between',
    }
}))

const PriceBlock = ({
    priceList,
}) => {
    const classes = useStyles()
    const lang = useContext(LangContext)
    const i18n = lang.i18n[lang.locale]

    return (
        <div className={classes.root}>
            <div className={classes.title}>
                {priceList.type === 'team_member' ? (
                    <Link to={`${priceList.slug}`} style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                        <Avatar
                            alt={priceList.avatar.alt ?? `Profile image ${priceList.title}`}
                            className={classes.avatar}
                            src={
                                priceList.avatar.gatsbyImageData?.images.fallback.src
                            }
                        />
                        {priceList.title}
                    </Link>
                ) : <>
                    {priceList.title}
                </>}

            </div>
            <div className={classes.list}>
                {priceList.services.map(service => (
                    <div className={classes.listItem}>
                        <div className={classes.itemTitle}>
                            {service.slug ? (
                                <Link to={`${service.slug}`}>
                                    {service.title}
                                </Link>
                            ) : <>
                                {service.title}</>}

                        </div>
                        {service.price && <div className={classes.itemPrice}>
                            {service.price} lei
                        </div>}
                        {priceList.type === 'document' && (
                            <a href={service.url} title={i18n.translations.priceList.titlePrices} target="_blank">
                                {i18n.translations.priceList.seePrices}
                            </a>
                        )}
                    </div>
                ))}
            </div>
        </div>
    )
}

export default PriceBlock

PriceBlock.propTypes = {
    priceList: PropTypes.object.isRequired,
}