import React, { useContext, useEffect, useState } from "react"
import PropTypes from "prop-types"
import shortid from 'shortid'
import Fuse from "fuse.js"

import makeStyles from "@material-ui/core/styles/makeStyles"
import LangContext from "../../contexts/LangContext"
import PriceBlock from "./PriceBlock"
import TextFilter from "../filters/TextFilter"

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
    },
    filter: {
        border: `1px solid #aaa`,
    }
}))

const PriceList = ({
    proceduresPage,
    teamMemberPage,
    priceDocument,
    servicePrices,
    teamMemberPrices
}) => {
    const classes = useStyles()
    const lang = useContext(LangContext)
    const i18n = lang.i18n[lang.locale]

    const prices = [
        {
            type: "document",
            id: shortid.generate(),
            title: i18n.translations.priceList.document,
            slug: null,
            avatar: null,
            services: [
                {
                    url: priceDocument.url,
                    slug: null,
                    title: "CAS",
                    price: ""
                }
            ]
        },
        {
            type: "procedure",
            id: shortid.generate(),
            title: i18n.translations.priceList.procedure,
            slug: null,
            avatar: null,
            services: servicePrices.map(servicePrice => ({
                url: null,
                slug: servicePrice.service.document?.data ? `/${proceduresPage.uid}/${servicePrice.service.document.data.categories[0].category.uid}/${servicePrice.service.document.uid}/` : null,
                title: servicePrice.service_name ?? servicePrice.service.document?.data?.title?.text ?? '',
                price: servicePrice.price
            }))
        },
        ...teamMemberPrices
            .filter(({ team_member }) => team_member.document?.data?.service_prices.length)
            .map(({ team_member }) => ({
                type: "team_member",
                id: shortid.generate(),
                title: team_member.document.data.name.text,
                slug: `/${teamMemberPage.uid}/${team_member.uid}/`,
                avatar: team_member.document.data.profile_image,
                services: team_member.document.data.service_prices.map(servicePrice => ({
                    url: null,
                    slug: servicePrice.service.document?.data ? `/${proceduresPage.uid}/${servicePrice.service.document.data.categories[0].category.uid}/${servicePrice.service.document.uid}/` : null,
                    title: servicePrice.service_name ?? servicePrice.service.document?.data?.title?.text ?? '',
                    price: servicePrice.price
                })).filter(service => service.price)
            }))
    ]

    const flatServices = []
    prices.forEach(price => {
        price.services.forEach(servicePrice => {
            flatServices.push({
                ...servicePrice,
                priceId: price.id
            })
        })
    })

    const fuse = new Fuse(flatServices, {
        includeScore: true,
        threshold: 0.1,
        keys: ["title"],
        shouldSort: false,
    })

    const [keywordFilter, setKeywordFilter] = useState('')
    const [filteredPrices, setFilteredPrices] = useState(prices)

    useEffect(() => {
        let filtered = prices

        if (keywordFilter.length) {
            const result = fuse.search(keywordFilter)

            filtered = filtered.map(price => {
                const foundServices = result.filter(r => r.item.priceId === price.id).map(r => r.item).filter(s => s.price)

                return {
                    ...price,
                    services: foundServices
                }
            })
        }

        setFilteredPrices([...filtered])
    }, [keywordFilter])

    const onFilter = keyword => {
        setKeywordFilter(keyword)
    }

    return (
        <div className={classes.root}>
            <div className={classes.filter}>
                <TextFilter
                    id={shortid.generate()}
                    placeholder={i18n.translations.priceList.search}
                    onFilter={onFilter}
                />
            </div>
            {filteredPrices.filter(price => price.services.length).map(price => <PriceBlock priceList={price} />)}
        </div>
    )
}

export default PriceList

PriceList.defaultProps = {
    priceDocument: null,
    servicePrices: [],
    teamMemberPrices: [],
}

PriceList.propTypes = {
    proceduresPage: PropTypes.object.isRequired,
    teamMemberPage: PropTypes.object.isRequired,
    priceDocument: PropTypes.object,
    servicePrices: PropTypes.array,
    teamMemberPrices: PropTypes.array,
}